<style lang="scss" scoped>
.tolttip-message {
  display: none !important;
}
.btn__contactar {
  background: #4b92fc !important;
  border-color: #4b92fc !important;
  font-weight: 500 !important;
  font-size: 1em !important;
  letter-spacing: 1px;
}
.btn-el-comercio {
  text-decoration: none;
  padding: 7.5px 10px;
  font-weight: 600;
  font-size: 1em;
  color: #ffffff;
  background-color: rgb(0, 38, 230);
  border-radius: 6px;
  border: 2px solid #0016b0;
}
.btn-el-comercio:hover {
  background-color: rgb(64, 204, 255);
  border: 2px solid rgb(64, 204, 255);
}
</style>
<template>
  <div>
    <CRow>
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
      ></loading>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong>Cancelar suscripción</strong>
          </CCardHeader>
          <CCardBody>
            <div class="form-group col-md-7">
              <label for="">Diario</label>
              <select
                class="form-control"
                name=""
                id=""
                @change="changeCategory($event)"
              >
                <option
                  v-for="item in arrCategory"
                  :value="item.id"
                  :key="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>

            <div class="form-group col-md-7">
              <label for="">Paquetes</label>
              <select
                class="form-control"
                name=""
                id=""
                @change="changeSubCategory($event)"
              >
                <option
                  v-for="item in arrSubCategory"
                  :value="item.id"
                  :key="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>

            <div class="form-group col-md-7">
              <label for="">Seleccione una suscripción</label>
              <select class="form-control" @change="changeSuscription($event)">
                <option value="">--Seleccionar--</option>
                <option
                  v-for="(item, index) in suscripciones"
                  :value="index + 1"
                  :key="item.id"
                >
                  {{ item.product }} - {{ item.id }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-7">
              <label>Ingrese su número telefónico</label>
              <p
                v-if="isCelular"
                style="
                  color: red;
                  font-size: 0.8em;
                  margin: 0 0 5px 0;
                  padding: 0;
                "
              >
                (*)El campo número telefónico está vacio.
              </p>
              <CInput
                id="telefono"
                placeholder="número telefónico"
                type="text"
                class="mb-4"
                v-model="celular"
                @keypress="validateNumber($event)"
                @keyup="validateLength($event)"
                disabled
              >
              </CInput>
            </div>
            <div class="form-group col-md-7">
              <button
                type="button"
                class="btn-el-comercio btn__contactar"
                @click="btnRegisterClicToCall()"
              >
                Contactar
              </button>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <img class="avatar__alicia" src="../../assets/img/nueva_alicia_pages_all.png" />
    <img class="avatar__alicia__mobile" src="../../assets/img/nueva_alicia_pages_all.png" />
  </div>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Cards",
  data: function () {
    return {
      suscripciones: [],
      nameSuscription: "Aún no ha seleccionado una suscripción",
      suscriptionSelected: {},
      categoryId: "",
      subCategoryId: "",
      celular: "",
      selectedCategoria: "0",
      show: true,
      isCollapsed: true,
      isLoading: false,
      fullPage: true,
      isCelular: false,
      idPosition: "",
      arrCategory: [
        { id: "", name: "--Seleccionar--" },
        { id: "1", name: "El Comercio" },
        { id: "2", name: "Gestión" },
      ],
      arrSubCategory: [{ id: "", name: "--Seleccionar--" }],
    };
  },
  components: {
    Loading,
  },
  methods: {
    validateNumber(event) {
      let val = event.key;
      let myRex = new RegExp("^[0-9]$");
      if (!myRex.test(val)) event.preventDefault();
    },
    validateLength(event) {
      let val = event.target.value;
      let len = 9;
      if (val.length > len) {
        this.celular = event.target.value.substring(0, len);
      }
      console.log("Value =>", this.celular);
    },
    changeCategory(event) {
      let vm = this;
      let id = event.target.value;
      vm.categoryId = id;
      vm.isLoading = true;

      if (id == "") {
        vm.arrSubCategory = [{ id: "", name: "--Seleccionar--" }];
      } else if (id == "1") {
        vm.arrSubCategory = [
          { id: "", name: "--Seleccionar--" },
          { id: "1", name: "El Comercio" },
          { id: "2", name: "El Comercio Impreso + Digital" },
          { id: "3", name: "El Comercio Digital" },
        ];
      } else if (id == "2") {
        vm.arrSubCategory = [
          { id: "", name: "--Seleccionar--" },
          { id: "4", name: "Gestión" },
          { id: "5", name: "Gestión Impreso + Digital" },
          { id: "6", name: "Gestión Digital" },
        ];
      }
      vm.suscripciones = [];
      vm.suscriptionSelected = {};
      vm.isLoading = false;

      vm.isLoading = false;
    },
    changeSubCategory(event) {
      let vm = this;
      let id = event.target.value;
      vm.subCategoryId = id;
      vm.isLoading = true;

      vm.suscripciones = [];
      vm.suscriptionSelected = {};

      if (id == "") {
        vm.isLoading = false;
      } else {
        let data = {
          nro_documento: localStorage.getItem("numeroDocumento"),
          tipo_documento: localStorage.getItem("tipoDocumento"),
          categoria: vm.categoryId,
          subcategoria: id,
          canal: "7",
        };
        axios
          .post(`${process.env.VUE_APP_ROOT_API}/listSuscripciones`, data)
          .then(function (res) {
            vm.isLoading = false;
            console.log("aca", res);
            if (res.data.data.code == "1") {
              vm.suscripciones = res.data.data.landing;
            } else {
              vm.isLoading = false;
              vm.$fire({
                title: "Información",
                text: "No tiene suscripciones en este paquete.",
                type: "info",
                timer: 4000,
              }).then((r) => {});
            }
          })
          .catch((error) => {
            vm.isLoading = false;
            vm.$fire({
              title: "Error",
              text: "No tiene suscripciones en este paquete.",
              type: "error",
              timer: 4000,
            }).then((r) => {});
          });
      }
    },
    changeSuscription(event) {
      let vm = this;
      let id = event.target.value;
      vm.isLoading = true;
      vm.suscriptionSelected = {};

      if (id == "") {
        vm.isLoading = false;
      } else {
        vm.isLoading = false;
        vm.idPosition = id;
        document.getElementById("telefono").disabled = false;
      }
    },
    async btnRegisterClicToCall(event) {
      //console.log("event", event);
      let vm = this;
      if (vm.celular == "") {
        vm.isCelular = true;
      } else {
        vm.isCelular = false;
      }

      if (vm.isCelular == false) {
        if (vm.celular.length == 7 || vm.celular.length == 9) {
        
           vm.isLoading = false;
          /* ACA VIENE LA VALIDACION DE LOS NUMEROS */
          let data = {
            document_number: localStorage.getItem("numeroDocumento"),
            document_type: localStorage.getItem("tipoDocumento"),
            positionId: vm.idPosition,
            phone: vm.celular,
          };
          //console.log("data", data);

          let response = await axios.post(`https://nlw80n31zb.execute-api.us-east-1.amazonaws.com/bpi/derivation/retention`,data)
            .then(function (res) {
              vm.isLoading = false;
              //console.log(res);
              return res.data.data;
            });

          if (response.code == "1") {
              vm.$fire({
                title: "Información",
                text: `Verifico que cuentas con un comprobante pendiente de pago que se debe regularizar antes de proceder con la cancelación de tu suscripción.\nEn breve, un asesor te llamará para asesorarte. Tardará unos minutos, estate atento(a) a nuestra llamada`,
                type: "info",
                timer: 2000,
              }).then((r) => {});

          }else if(response.code == "2"){
            vm.$fire({
              //title: "Información",
              text: "Uno de nuestros asesores se comunicará contigo para continuar con el proceso de cancelación en los próximos minutos.",
               type: "info",
              timer: 3000,
            }).then((r) => {});
          }else{
            vm.$fire({
              title: "Información",
              text: "Lo siento, ingresaste datos incorrectos o no cuentas con suscripción activa",
               type: "info",
              timer: 3000,
            }).then((r) => {});
          }


        } else {
          vm.isLoading = false;
          vm.$fire({
            title: "Seguridad",
            text: "El celular ingresado debe contener 7 u 9 dígitos.",
            type: "info",
            timer: 3000,
          }).then((r) => {});
        }
      }
    },
  },
  filters: {
    currency(value) {
      return `S/ ${value}`;
    },
  },
  mounted() {
    let vm = this;
    axios.interceptors.request.use(
      (config) => {
        vm.isLoading = true;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    $("#pgb-container").hide();
  },
};
</script>
